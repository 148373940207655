import { wmsPlusHttp } from '@/utils/http/index';

/**
 * @description:盘点单据列表分页查询
 * @api文档：https://yapi.ops.yunlizhi.cn/project/382/interface/api/16043
 */
export function countOrderPage(params, data) {
  return wmsPlusHttp.post('/warehouse_management_system/count_order/page', data, {
    params,
  });
}

/**
 * @description:根据盘点单据ID查询盘点单据详情
 * @api文档：https://yapi.ops.yunlizhi.cn/project/382/interface/api/16041
 */
export function countOrderDetail(data) {
  return wmsPlusHttp.post('/warehouse_management_system/count_order/query', data);
}
/**
 * @description:盘点单新增:生成盘点单据、盘点明细、盘点任务
 * @api文档：https://yapi.ops.yunlizhi.cn/project/382/interface/api/16045
 */
export function countOrderGenerate(data) {
  return wmsPlusHttp.post('/warehouse_management_system/count_order/generate', data);
}

/**
 * @description: 根据盘点单据ID查询盘点任务组信息
 * @api文档：https://yapi.ops.yunlizhi.cn/project/382/interface/api/16047
 *
 */
export function queryTasks(data) {
  return wmsPlusHttp.post('/warehouse_management_system/count_order/query/tasks', data);
}

/**
 * @description: 根据盘点任务ID获取盘点子任务明细
 * @api文档：https://yapi.ops.yunlizhi.cn/project/382/interface/api/16049
 *
 */
export function querySubTasksPage(params, data) {
  return wmsPlusHttp.post('/warehouse_management_system/subtasks/page', data, {
    params,
  });
}

/**
 * @description: 盘点单据页面，任务预览
 * @api文档：https://yapi.ops.yunlizhi.cn/project/382/interface/api/16051
 *
 */
export function taskPreview(data) {
  return wmsPlusHttp.post('/warehouse_management_system/count_order/task/preview', data);
}

/**
 * @description: 盘点单据关闭功能
 * @api文档：https://yapi.ops.yunlizhi.cn/project/382/interface/api/16053
 *
 */
export function countOrderClose(data) {
  return wmsPlusHttp.post('/warehouse_management_system/count_order/close', data);
}

/**
 * @description: 生成盘点差异数据
 * @api文档：https://yapi.ops.yunlizhi.cn/project/382/interface/api/16057
 *
 */
export function generateDiffCount(data) {
  return wmsPlusHttp.post('/warehouse_management_system/count_order/generate/diff/count', data);
}

/**
 * @description:查询货主列表
 * @api文档：https://yapi.ops.yunlizhi.cn/project/388/interface/api/12400
 */
export function shipperList(data) {
  return wmsPlusHttp.post('/warehouse_foundation/shipper/v2/list', data);
}

/**
 * @description: 货品分类
 * @api文档：https://yapi.ops.yunlizhi.cn/project/271/interface/api/9292
 */
export function getGoodsLevelTree(data) {
  return wmsPlusHttp.post('/goods_level/tree', data);
}

/**
 * @description: 库区信息表 分页查询
 * @api文档：https://yapi.ops.yunlizhi.cn/project/388/interface/api/11164
 */

export function warehouseZonePage(params, data) {
  return wmsPlusHttp.post('/warehouse_foundation/zone/page', data, {
    params,
  });
}

/**
 * @description: 盘点明细导出
 * @api文档：https://yapi.ops.yunlizhi.cn/project/382/interface/api/15993
 */

export function countOrderDetailExport(data, countOrderNo) {
  return wmsPlusHttp.download('/warehouse_management_system/count_order_detail/export', data, { fileName: `盘点明细(${countOrderNo})`, extension: 'xlsx' });
}

/**
 * @description: 盘点导入之数据校验
 * @api文档：https://yapi.ops.yunlizhi.cn/project/382/interface/api/15995
 */

export function countOrderImportCheck(data, params) {
  return wmsPlusHttp.post('/warehouse_management_system/count_order_detail/import/check', data, {
    params,
  });
}

/**
 * @description: 盘点明细导入
 * @api文档：https://yapi.ops.yunlizhi.cn/project/382/interface/api/15997
 */

export function countOrderImport(data) {
  return wmsPlusHttp.post('/warehouse_management_system/count_order_detail/import', data);
}

/**
 * @description: 盘点单据确认
 * @api文档：https://yapi.ops.yunlizhi.cn/project/382/interface/api/16081
 */

export function countOrderConfirm(data) {
  return wmsPlusHttp.post('/warehouse_management_system/count_order/result/confirm', data);
}

/**
 * @description: 下载模板
 * @api文档：https://yapi.ops.yunlizhi.cn/project/382/interface/api/12508
 *
 */
export function downLoadTemplate(data) {
  return wmsPlusHttp.download('/warehouse_management_system/file/template/download', data, { fileName: '盘点明细模板', extension: 'xlsx' });
}
