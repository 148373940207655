<template>
  <nh-drawer
    v-model="drawerVisible"
    :before-close="handleClose"
    destroy-on-close
    title="盘点单详情"
    size="1111px"
    :track="{
      name_zh: `在库管理/盘点管理/抽屉-详情`,
      trackName: $route.path
    }"
  >
    <div v-loading="loading.init" class="detail-content">
      <div class="basic-info">
        <nh-dialog-form label-width="120px" label-position="left">
          <nh-dialog-form-item :span="8" label="盘点单号:">
            {{ basicInfoData.countOrderNo }}
          </nh-dialog-form-item>
          <nh-dialog-form-item :span="8" label="盘点类型:">
            {{ filterLabel(basicInfoData.countType,COUNT_TYPE) }}
          </nh-dialog-form-item>
          <nh-dialog-form-item :span="8" label="盘点方式:">
            {{ filterLabel(basicInfoData.operationType,OPERATION_TYPE) }}
          </nh-dialog-form-item>
          <nh-dialog-form-item :span="8" label="是否盲盘:">
            {{ filterLabel(basicInfoData.isBlindCount,IS_SHOW_STOCK_AMOUNT) }}
          </nh-dialog-form-item>
          <nh-dialog-form-item :span="8" label="是否锁库盘点:">
            {{ filterLabel(basicInfoData.isLockStockAmount,IS_LOCK_STOCK_AMOUNT) }}
          </nh-dialog-form-item>
          <nh-dialog-form-item :span="8" label="是否过滤空库位:">
            {{ filterLabel(basicInfoData.isFilterEmptyStorehouse,IS_FILTER_EMPTY_STOREHOUSE) }}
          </nh-dialog-form-item>
          <nh-dialog-form-item :span="8" label="是否双人盘点:">
            {{ filterLabel(basicInfoData.isDoubleCheck,IS_DOUBLE_CHECK) }}
          </nh-dialog-form-item>
          <!-- 业务不支持 暂时屏蔽 2022/4/20 11:30-->
          <!-- <nh-dialog-form-item :span="8" label="是否包含冻结库存:">
            {{ filterLabel(basicInfoData.isIncludeFrozenStock,IS_DOUBLE_CHECK) }}
          </nh-dialog-form-item> -->
          <!-- 业务不支持暂时屏蔽 2022/4/22 10:00-->
          <!-- <nh-dialog-form-item :span="8" label="是否过滤占用库位:">
            {{ filterLabel(basicInfoData.isFilterLockedLocation,IS_DOUBLE_CHECK) }}
          </nh-dialog-form-item> -->
          <nh-dialog-form-item :span="8" label="盘点单状态:">
            {{ filterLabel(basicInfoData.status,COUNT_STATUS) }}
          </nh-dialog-form-item>
          <nh-dialog-form-item :span="8" label="创建人:">
            {{ basicInfoData.createUserName }}
          </nh-dialog-form-item>
          <nh-dialog-form-item :span="8" label="创建时间:">
            {{ Moment.format(basicInfoData.createTime) }}
          </nh-dialog-form-item>
          <nh-dialog-form-item :span="8" label="盘点完成时间:">
            {{ Moment.format(basicInfoData.completeTime) }}
          </nh-dialog-form-item>
        </nh-dialog-form>
      </div>
      <div class="detail-tabs-box">
        <el-tabs v-model="activeName">
          <el-tab-pane label="盘点单详情" :name="DETAIL_TABS.COUNT_ORDER_DETAIL">
            <PlTable
              :loading="loading.countOrderDetail"
              :data="goodsDetails"
              :columns="GOODSDETAILSTABLECOLUMN(GOODS_QUALITY,basicInfoData.isDoubleCheck)"
            />
          </el-tab-pane>
          <el-tab-pane label="盘点任务" :name="DETAIL_TABS.COUNT_TASK">
            <el-collapse
              v-model="collapseActiveNames"
              accordion
              @change="collapseHandleChange"
            >
              <el-collapse-item
                v-for="item in tasksList"
                :key="item.id"
                :name="item.id"
              >
                <template #title>
                  <el-row style="width: 100%;">
                    <el-col :span="5">
                      任务号：{{ item.taskNo }}
                    </el-col>
                    <el-col :span="3">
                      状态：{{ TASK_STATUS[item.taskStatus] }}
                    </el-col>
                    <el-col :span="4">
                      盘点人：{{ item.countUserName }}
                    </el-col>
                    <el-col :span="6">
                      盘点开始时间：{{ Moment.format(item.countStartTime) }}
                    </el-col>
                    <el-col :span="6">
                      盘点结束时间：{{ Moment.format(item.countEndTime) }}
                    </el-col>
                  </el-row>
                </template>
                <!-- TODO:恒志组件bug后期修复之后更改 -->
                <el-table :data="item.subtasksList">
                  <el-table-column
                    type="index"
                    label="序号"
                    width="50"
                  />
                  <el-table-column
                    v-for="(it,index) in TASKDETAILSTABLECOLUMN"
                    :key="index"
                    :prop="it.prop"
                    :label="it.label"
                    :min-width="it.minWidth"
                    :formatter="it.formatter"
                  />
                </el-table>
                <!-- <PlTable
                  :loading="loading.collapseHandleChange"
                  :data="item.subtasksList"
                  :columns="TASKDETAILSTABLECOLUMN"
                /> -->
              </el-collapse-item>
            </el-collapse>
          </el-tab-pane>
          <el-tab-pane label="关联的盘点单" :name="DETAIL_TABS.COUNT_RELATION">
            <PlTable
              :loading="loading.countOrderDetail"
              :data="relationDetails"
              :columns="RELATIONDETAILSTABLECOLUMN"
            />
          </el-tab-pane>
        </el-tabs>
      </div>
      <div v-if="isEdit" class="count-order-btn-box">
        <nh-button
          v-if="basicInfoData.showDiffReCountButton"
          type="primary"
          size="small"
          :loading="loading.createdDiffs"
          @click="createdDiffs"
        >
          生成差异复盘
        </nh-button>
        <nh-button
          v-if="basicInfoData.showResultConfirmButton"
          type="primary"
          size="small"
          :loading="loading.handleConfirm"
          @click="handleConfirm"
        >
          盘点结果确认
        </nh-button>
      </div>
    </div>
  </nh-drawer>
</template>

<script>
import loadingMixin from '@thales/loading';
import Moment from '@/utils/moment';
import {
  DETAIL_TABS, GOODSDETAILSTABLECOLUMN, TASKDETAILSTABLECOLUMN, RELATIONDETAILSTABLECOLUMN,
} from './fileds';
import {
  COUNT_TYPE,
  OPERATION_TYPE,
  IS_SHOW_STOCK_AMOUNT,
  IS_LOCK_STOCK_AMOUNT,
  IS_DOUBLE_CHECK,
  TASK_GROUP_TYPE,
  COUNT_STATUS,
  TASK_STATUS,
  IS_FILTER_EMPTY_STOREHOUSE,
  SUBTASK_STATUS,
} from '../constant';
import {
  countOrderDetail, queryTasks, querySubTasksPage, countOrderConfirm, generateDiffCount,
} from '../api';

export default {
  name: 'LibraryTransferDetails',
  mixins: [loadingMixin],
  props: {
    idData: {
      type: Object,
      default: () => {},
    },
  },
  emits: ['onSuccess', 'handleClickStore'],
  data() {
    return {
      drawerVisible: false,
      Moment,
      DETAIL_TABS,
      COUNT_TYPE,
      OPERATION_TYPE,
      IS_SHOW_STOCK_AMOUNT,
      IS_LOCK_STOCK_AMOUNT,
      IS_DOUBLE_CHECK,
      TASK_GROUP_TYPE,
      COUNT_STATUS,
      TASK_STATUS,
      IS_FILTER_EMPTY_STOREHOUSE,
      // TRANSFER_TYPE,
      GOODSDETAILSTABLECOLUMN,
      TASKDETAILSTABLECOLUMN,
      RELATIONDETAILSTABLECOLUMN,
      loading: {
        init: false,
        countOrderDetail: false,
        getSubtaskList: false,
        createdDiffs: false,
        handleConfirm: false,
      },
      activeName: DETAIL_TABS.COUNT_ORDER_DETAIL,
      isEdit: false,
      collapseActiveNames: '',
      basicInfoData: {},
      relationDetails: [],
      goodsDetails: [],
      tasksList: [],
      countOrderId: '',
    };
  },
  computed: {
    GOODS_QUALITY() {
      return this.$dictionaryFiledMap.GOODS_QUALITY || [];
    },
  },
  watch: {
    idData: {
      deep: true,
      handler(val) {
        if (val.num) {
          this.open(val.id);
        }
      },
    },
  },
  methods: {
    open(id, isEdit) {
      this.countOrderId = id;
      this.isEdit = isEdit;
      this.init();
      this.drawerVisible = true;
    },
    async init() {
      await this.countOrderDetail();
      await this.queryTasks();
    },
    async countOrderDetail() {
      const result = await countOrderDetail({ id: this.countOrderId });
      this.basicInfoData = result || {};
      this.goodsDetails = result.details || [];
      this.relationDetails = result.associatedCountOrders || [];
    },
    async queryTasks() {
      const result = await queryTasks({ id: this.countOrderId });
      this.tasksList = result || [];
      this.collapseActiveNames = this.tasksList[0].id;
      this.collapseHandleChange(this.tasksList[0].id);
    },
    async collapseHandleChange(val) {
      if (!val) {
        return;
      }
      const task = this.tasksList.find((t) => t.id === val);
      if (!task.subtasksList) {
        const result = await querySubTasksPage({ page: 1, size: 9999 }, { taskId: val });
        this.$nextTick(() => {
          task.subtasksList = result.records.map((item) => {
            const newItem = item;
            if (item.subtaskStatus === SUBTASK_STATUS[1].value
              || item.subtaskStatus === SUBTASK_STATUS[2].value) {
              newItem.diffAmount = (item.completedAmount * 1000 - item.amount * 1000) / 1000;
            }
            if (item.subtaskRecords.length > 0) {
              newItem.onloadContainerCode = item.subtaskRecords[item.subtaskRecords.length - 1]
                .containerCode;
              newItem.countDateTime = Moment.format(
                item.subtaskRecords[item.subtaskRecords.length - 1].operationTime,
              );
            }

            return newItem;
          }) || [];
        });
      }
    },
    async  handleConfirm() {
      if (this.basicInfoData.isDoubleCheck && this.basicInfoData.isDiff) {
        await this.$alert('双人盘点差异需要进行复盘！', '提示', {
          confirmButtonText: '确定',
        });
      } else {
        if (this.basicInfoData.isDiff) {
          await this.$confirm('是否确认生成库存调整单？', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning',
          });
        }
        await countOrderConfirm({ countOrderId: this.basicInfoData.id });
        this.$message({ type: 'success', message: '操作成功！' });
        this.$emit('onSuccess');
        this.handleClose();
      }
    },
    async createdDiffs() {
      await this.$confirm('是否确认生成差异复盘单？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      });
      await generateDiffCount({ countOrderId: this.basicInfoData.id });
      this.$message({ type: 'success', message: '操作成功！' });
      this.$emit('onSuccess');
      this.handleClose();
    },
    filterLabel(val, arr) {
      const filertArr = arr && arr.find((item) => item.value === val);
      return filertArr && filertArr.label;
    },
    handleClose() {
      this.drawerVisible = false;
    },
  },
};
</script>
<style lang="scss" scoped>
.detail-content {
  padding: 24px;

  .basic-info {
    padding: 24px;
  }

  .count-order-btn-box {
    margin-top: 24px;
    text-align: center;
  }
}
</style>
