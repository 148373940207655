import { DateTime } from '@/constant/tableConfig';
import {
  TEMPERATURE_LAYER, COUNT_STATUS, COUNT_TYPE, SUBTASK_STATUS,
} from '../constant';

function filterLabel(val, arr) {
  const filterArr = arr.find((item) => item.value === val);
  return filterArr ? filterArr.label : '';
}

export const GOODSDETAILSTABLECOLUMN = (GOODS_QUALITY, isDoubleCheck) => {
  const diffAmount2Str = isDoubleCheck ? [{
    label: '盘点差异2',
    prop: 'diffAmount2Str',
    minWidth: 100,
  }] : [];
  return [{
    label: '序号',
    type: 'index',
    width: 50,
  }, {
    label: '库位',
    prop: 'storehouseCode',
    minWidth: 80,
  }, {
    label: '货主货品编码',
    prop: 'shipperGoodsCode',
    minWidth: 180,
  }, {
    label: '货品名称',
    prop: 'goodsName',
    minWidth: 100,
  }, {
    label: '货主',
    prop: 'shipperName',
    minWidth: 100,
  }, {
    label: '温层',
    prop: 'temperatureLayer',
    minWidth: 80,
    formatter: ({ temperatureLayer }) => TEMPERATURE_LAYER[temperatureLayer],
  }, {
    label: '批次号',
    prop: 'batchNo',
    minWidth: 120,
  }, {
    label: '生产日期',
    prop: 'productDate',
    ...DateTime,
  }, {
    label: '容器',
    prop: 'containerCode',
    minWidth: 100,
  }, {
    label: '货品质量',
    prop: 'goodsQuality',
    minWidth: 100,
    formatter: ({ goodsQuality }) => filterLabel(goodsQuality, GOODS_QUALITY),
  }, {
    label: '系统库存量(实时)',
    prop: 'amountStr',
    minWidth: 100,
  }, {
    label: '盘点差异1',
    prop: 'diffAmount1Str',
    minWidth: 100,
  },
  ...diffAmount2Str,
  {
    label: '当前状态',
    prop: 'status',
    minWidth: 100,
    formatter: ({ status }) => filterLabel(status, COUNT_STATUS),
  },
  {
    label: '最后盘点人',
    prop: 'lastCountUserName',
    minWidth: 100,
  },
  {
    label: '最后盘点时间',
    prop: 'lastCountTime',
    ...DateTime,

  },
  ];
};
export const TASKDETAILSTABLECOLUMN = [
//   {
//   label: '序号',
//   type: 'index',
//   width: 50,
// },
  {
    label: '库位',
    prop: 'unloadStorehouseCode',
    minWidth: 80,
  }, {
    label: '货品编码',
    prop: 'goodsCode',
    minWidth: 180,
  }, {
    label: '货品名称',
    prop: 'goodsName',
    minWidth: 100,
  }, {
    label: '货主',
    prop: 'shipperName',
    minWidth: 100,
  }, {
    label: '温层',
    prop: 'temperatureLayer',
    minWidth: 80,
    formatter: ({ temperatureLayer }) => TEMPERATURE_LAYER[temperatureLayer],
  }, {
    label: '批次号',
    prop: 'batchNo',
    minWidth: 120,
  },
  // 产品与后端协商，暂时取消
  //  {
  //   label: '生产日期',
  //   prop: 'productDate',
  //   ...DateTime,
  // },
  {
    label: '系统库存量',
    prop: 'amount',
    minWidth: 120,
  }, {
    label: '盘点量',
    prop: 'completedAmount',
    minWidth: 100,
  }, { // TODO:
    label: '差异量',
    prop: 'diffAmount',
    minWidth: 100,
  }, {
    label: '系统托盘码',
    prop: 'unloadContainerCode',
    minWidth: 100,
  }, { // TODO:
    label: '盘点托盘码',
    prop: 'onloadContainerCode',
    minWidth: 100,
  }, {
    label: '状态',
    prop: 'subtaskStatus',
    minWidth: 100,
    formatter: ({ subtaskStatus }) => filterLabel(subtaskStatus, SUBTASK_STATUS),
  }, { // TODO:
    label: '盘点时间',
    prop: 'countDateTime',
    ...DateTime,
  },
];

export const RELATIONDETAILSTABLECOLUMN = [{
  label: '序号',
  type: 'index',
  width: 50,
}, {
  label: '盘点单号',
  prop: 'countOrderNo',
  minWidth: 180,
}, {
  label: '盘点类型',
  prop: 'countType',
  minWidth: 100,
  formatter: ({ countType }) => filterLabel(countType, COUNT_TYPE),
}, {
  label: '关联单号',
  prop: 'parentCountOrderNo',
  minWidth: 100,
}, {
  label: '状态',
  prop: 'status',
  minWidth: 80,
  formatter: ({ status }) => filterLabel(status, COUNT_STATUS),
}, {
  label: '包含库位数',
  prop: 'totalStorehouseNum',
  minWidth: 90,
}, {
  label: '包含任务数',
  prop: 'totalSubTasksNum',
  minWidth: 120,
}, {
  label: '创建时间',
  prop: 'createTime',
  ...DateTime,
}, {
  label: '盘点完成时间',
  prop: 'completeTime',
  ...DateTime,
},
];
export const DETAIL_TABS = {
  COUNT_ORDER_DETAIL: 'COUNT_ORDER_DETAIL', // 盘点单详情
  COUNT_TASK: 'COUNT_TASK', // 盘点任务
  COUNT_RELATION: 'COUNT_RELATION', // 关联的盘点单
};
