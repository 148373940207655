import { formatKeyValueObject } from '@/utils/base';

export const COUNT_TYPE = [
  {
    value: 'SAMPLING_COUNT',
    label: '抽盘',
  },
  {
    value: 'DYNAMIC_COUNT',
    label: '动碰盘点',
  },
  {
    value: 'RE_COUNT',
    label: '复盘',
  },
  {
    value: 'RANDOM_COUNT',
    label: '随机盘点',
  },
];
export const ADD_COUNT_TYPE = [
  {
    value: 'SAMPLING_COUNT',
    label: '抽盘',
  },
  {
    value: 'DYNAMIC_COUNT',
    label: '动碰盘点',
  },
];
export const COUNT_TYPE_ENUM = formatKeyValueObject(COUNT_TYPE);

export const OPERATION_TYPE = [
  {
    value: 'PDA',
    label: 'PDA',
  },
  {
    value: 'PAPER',
    label: '纸单',
  },
  {
    value: 'AUTOMATIC',
    label: '自动化',
  },
];

export const TASKGENERATE_TYPE = [
  {
    value: 'BY_STOREHOUSE_GOODS',
    label: '库位+商品',
  },
  {
    value: 'BY_STOREHOUSE',
    label: '库位',
  },
];

export const COUNT_STATUS = [
  {
    value: 'COUNT',
    label: '待盘点',
  },
  {
    value: 'COUNTING',
    label: '盘点中',
  },
  {
    value: 'CONFIRMING',
    label: '待确认',
  },
  {
    value: 'CLOSE',
    label: '已关闭',
  },
  {
    value: 'COMPLETE',
    label: '已完成',
  },
];
export const COUNT_STATUS_EN = {
  COUNT: 'COUNT',
  COUNTING: 'COUNTING',
  CONFIRMING: 'CONFIRMING',
  CLOSE: 'CLOSE',
  COMPLETE: 'COMPLETE',
};
export const SUBTASK_STATUS = [
  {
    value: 'PREPARE',
    label: '准备中',
  },
  {
    value: 'DOING',
    label: '作业中',
  },
  {
    value: 'FINISHED',
    label: '已完结',
  },
  {
    value: 'CLOSE',
    label: '已关闭',
  },
];
export const IS_SHOW_STOCK_AMOUNT = [
  {
    value: true,
    label: '是',
  },
  {
    value: false,
    label: '否',
  },
];

export const IS_LOCK_STOCK_AMOUNT = [
  {
    value: true,
    label: '是',
  },
  {
    value: false,
    label: '否',
  },
];

export const IS_DOUBLE_CHECK = [
  {
    value: true,
    label: '是',
  },
  {
    value: false,
    label: '否',
  },
];

export const IS_FILTER_EMPTY_STOREHOUSE = [
  {
    value: true,
    label: '是',
  },
  {
    value: false,
    label: '否',
  },
];

export const TASK_GROUP_TYPE = [
  {
    value: 'BY_ZONE',
    label: '按库区',
  },
  {
    value: 'BY_TEMPERATURE_LAYER',
    label: '按温层',
  },
];
export const IS_DIFF = [
  {
    value: true,
    label: '是',
  },
  {
    value: false,
    label: '否',
  },
];
export const IS_DIFF_EN = {
  HAS_DIFF: '有',
  NO_DIFF: '无',
};
export const IS_LOCK_STOCK_AMOUNT_EN = {
  LOCK: '是',
  NO_LOCK: '否',
};
// 温层
export const TEMPERATURE_LAYER = {
  NORMAL: '常温',
  COLD: '冷冻',
  REFRIGERATE: '冷藏',
  CONSTANT: '恒温',
};

export const TASK_STATUS = {
  PREPARE: '准备中',
  DOING: '作业中',
  FINISHED: '已完结',
  CLOSE: '已关闭',
};
